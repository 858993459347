
body{
  font-family: 'Open Sans', sans-serif;
}

.title{
  color: white;
  z-index: 1;

}

a:hover {
  color: rgb(255, 174, 0) !important;
}

.logo-light{
  height: 80px;
}
.logo-dark{
  height: 80px;
}

.servicesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: 0 auto;

  }

  .box {
    display: flex;
    width: 300px;
    height: 460px;
    margin:20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 4px solid gold;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 20px gold;
    /* animation: pulsate 2s ease-out;
    animation-iteration-count: infinite; */
    /* box-shadow: 0 0 20px rgba(234, 255, 0, 0.7); */
    background-color: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));

  /* animation: rotate-glow 5s ease-in-out infinite; */
  }

  .boxInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
  font-size: 20px;
  padding-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}

.heroBoxInner{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));

}

.exploreButton {
  padding: 10px 10px;
  color: rgb(16, 0, 0);
  width: 150px;
  border-radius: 20px;
  border: 2px solid #ffdd00;
  transition: border-width 0.3s ease-in-out;
  background-color: transparent;
  color: #fff;
}

.exploreButton:hover {
  border-width: 3px 3px 0 3px;
}

  /* .box::after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;

  } */

  .box p {
    font-size: 20px;
    color: #fff;
    z-index: 1;
  }

  @keyframes rotate-glow {
  0% {

    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
  }

  75% {
    box-shadow: 0 0 40px rgba(0, 128, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 50px rgba(0, 0, 255, 0.7);
  }
}

  @keyframes pulsate {
    0% {
      transform: scale(0.95);
      opacity: 0.7;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.7;
    }
  }

  .HeroContainer {
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));

  }

  .description{
    color: #ffffff;
    }

    .seconHeader{
    font-size:10vh;
    text-align: center;

  }

  .theme-gradient-golden {
  /* transform: translate (-50%,-50%); */
  font-size: 72px;
  background: linear-gradient( #FFAE00, #775900);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  .standard-header {
   font-size: 50px;
  }



  /* Css for swipe to unlock website button */
  .outer-div {
    position: relative;
    width: 150px;
    height: 60px;
    border-radius: 20px;
    background-color: gold;
  }

  .inner-div {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    cursor: grab;
  }

  .inner-div:active {
    cursor: grabbing;
  }

    /* Css for swipe to unlock website button ends */

  /* Ripples CSS starts */
  .outer-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(1, 0, 0);
    position: relative;
    overflow: hidden;
    /* animation: ripple 2s ease-in-out infinite; */

  }

  .inner-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    animation: ripple 2s ease-in-out infinite;
    background-position: center;
    background-repeat: no-repeat;
  }


  @keyframes ripple {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
/* Ripple css ends   */

.exploreBrandLink{
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: rgba(255, 238, 0, 0.2);
  border-radius: 55px;
}

.exploreBrandLink:hover{
  color: #FFD700;
  font-weight: bolder;
}

.exploreBrandText{
padding-right: 5px;
}



.wideTileContainer{
  width: 1390px;
  height: 900px;
  background-color: #d7d7d7;
  margin: auto;
  border-radius: 3%;
}


.logoScrollContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  opacity: 0.3;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 2px;
}

.logoScrollInnerContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  /* animation: logoScroll 20s linear infinite; */
}

/* CSS for Logo scroll marque effect */
.logoScrollItem {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 100px;
  -moz-transform: translateX(100%);
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
                -moz-animation: scroll-left 2s linear infinite;
                -webkit-animation: scroll-left 2s linear infinite;
                animation: scroll-left 15s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
  }
  100% {
      -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
      -webkit-transform: translateX(100%);
  }
  100% {
      -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
  }
  100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
  }
}

/* CSS for Logo scroll marque effect ends */

/* Gold gradient for text. Background-clip text */
 .goldTextClip{
  background: linear-gradient(95deg, rgba(155,255,133) 15%, red 45%, orange 75%, gold 100%) 98% / 200% 100%;
  text-transform: capitalize;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
 }

 .btn-gold-gradient {
  background: linear-gradient(95deg, rgba(155,255,133) 15%, red 45%, orange 75%, gold 100%) 98% / 200% 100%;
  text-transform: capitalize;
  height: 50px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-size: medium;
  opacity: 0.9;
 }

 .btn-gold-gradient:hover{
  opacity:1
 }

.sideImage{
  border-radius: 2%;
}

.rn-timeline-wrapper{
  width: 90%;
  margin-left: 2%;
}


.tileContainerOuter {
  height: 695px;
  width: 685px;
  border-radius: 10%;
  margin: 20px;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode:hue;
  opacity: 1;
transition: opacity 0.5s ease-in-out;
}

.tileContainerInner{
  width: 100%;
  height: 100%;
  border-radius: 10%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  /* background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%; */
  /* background: linear-gradient(48deg, rgba(0, 0, 0, 0.6), rgba(255, 0, 212, 0.6), rgba(255, 149, 0, 0.6)); */
           /* #4900db 0%,
           #ff00d4 50%,
           #ff9500 100%) */
}


.tilesGroupContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tileHeader{
  font-size: 50px;
  line-height: 60px;
}

.tileText{
  font-size: 20px;
  color: #fff;
  padding: 60px;
}

/* Tiles on Standard laptops */
@media screen and (min-width: 768px) and (max-width: 1410px)  {
  .tileContainerOuter {
    height: 552px;
    width: 590px;
    background-color: rgba(0, 0, 0, 0.5);
    /* background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%; */
    border-radius: 5%;
    margin: 20px;
    opacity: 1;
  transition: opacity 0.5s ease-in-out;
  }

  .tileContainerInner{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .tileHeader {
    font-size: 40px;
    line-height: 50px;
  }

  .tileText{
    font-size: 16px;
    color: #fff;
    padding: 45px;
  }

  .standard-header {
    font-size: 40px
   }

   .logoScrollContainer{
    bottom: -150px;
   }
}



/* MediaQury for mobile phones */

@media only screen and (max-width: 480px) {
  /* Styles for mobile phones */

    .HeroContainer{
      height: 100vh;
    }

  .logoScrollContainer{
    bottom: 50px;
   }

  .tileContainerOuter {
    height: 552px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin: 10px;
    opacity: 1;
  transition: opacity 0.5s ease-in-out;
  }


  .tileContainerInner{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .tileHeader {
    font-size: 30px;
    line-height: 40px;
  }

  .tileText{
    font-size: 15px;
    color: #fff;
    padding: 15px;
  }

  .standard-header {
    font-size: 30px
   }

   .seconHeader{
    font-size: 40px;
    margin-top: 30px;

   }
}

@media screen and (min-width: 1411px) and (max-width: 1761px) {

  .tileContainerOuter {
    height: 695px;
    width: 685px;
    border-radius: 10%;
    /* background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%; */
    margin: 20px;
    opacity: 1;
  transition: opacity 0.5s ease-in-out;
  }
}

@media only screen and (max-width: 800px) {
  .box {
    flex: 0 0 100%;
    max-width: 100%;
  }
}





